<template>
  <section
    class="footer"
    id="footer_id">
    <div class="footer__container">
      <div class="footer__address">
        Ваш Туристический Советник<br><br>
        <span>ИП</span> Слук Евгения Сергеевна<br>
        <span>ИНН</span> 402508100507<br>
        <span>ОГРН</span> – 318774600203792<br>
        115142, г. Москва, <br>Коломенская набережная, 10-112<br>
      </div>

      <div class="footer__number">
        <p>
          pismo@vts-travel.ru
        </p>

        <p>
          <a
            class="footer__phone"
            href="tel:+79261717162">
            +7-926-171-71-62
          </a>
        </p>

        <MessengerIcons :color="false"/>
      </div>
    </div>
  </section>
</template>

<script>
import MessengerIcons from '../ui/MessengerIcons.vue'

export default {
  name: 'FooterVue',
  components: {
    MessengerIcons
  },
  props: {
  },
  setup () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
span {
  font-family: 'Maven Pro', sans-serif;
  font-weight: 400;
  font-size: 22px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1921px) {
    font-size: 1.6vw;
  }
}
.footer {
  background-color: #052831;
  color: #FFFFFF;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
  font-size: 22px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
  }

  @media screen and (min-width: 1921px) {
    font-size: 1.6vw;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 80vw;
    margin: auto auto;
    padding: 20px 0px;
    gap: 2vw;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__address {
    margin-bottom: 1vw;
  }

  &__number {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1vw;

    @media screen and (max-width: 1024px) {
      align-items: center;
    }
  }

  &__phone {
    text-decoration: none;
    color: #FFFFFF;
  }

  :deep(.messenger-icons) {
    gap: 0;
  }

  :deep(img) {
    width: 80%;

    @media screen and (max-width: 1024px) {
      width: 65%;
    }
  }
}
</style>
