<template>
  <header
    class="header"
    id="header_id">
    <div class="header__container">
      <BurgerMenu/>

      <a href="#">
        <img
          class="header__logo"
          src="../icons/header/logo.svg"
          alt="Ваш туристический советник">
      </a>

      <Navigation/>

      <div class="header__contact">
        <a href="tel:+79261717162">
          <img
            class="header__icon-24_7"
            src="../icons/header/phone.png"
            alt="На связи 24/7">
        </a>

        <a
          class="header__phone-number"
          href="tel:+79261717162">
          +7 926 171-71-62
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import BurgerMenu from '@/components/elements/BurgerMenu.vue'
import Navigation from '../elements/Navigation.vue'

export default {
  name: 'HeaderVue',
  components: {
    BurgerMenu,
    Navigation
  },
  props: {
  },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0vw;
  width: 100vw;
  padding: 2.101vw 0;
  background: rgba(169, 200, 214, 0.7);
  z-index: 3;

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: 0.830vw;
  }

  &__phone-number {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #052831;
    text-decoration: none;
    white-space: nowrap;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    @media screen and (min-width: 1921px) {
      font-size: 1.3vw;
    }
  }
}
</style>
