<template>
  <router-view/>
</template>

<script>
export default {
  name: 'AppVue',
  components: {
  },
  props: {
  },
  setup () {
  }
}
</script>

<style lang="scss">
</style>
