<template>
  <section class="package-tours" id="package_tours_id">
    <div class="package-tours__container container">
      <div class="container__info">
        <div class="description">
          <h2 class="container__title">
            Пакетные туры
          </h2>

          <p>
            {{ data.description }}
          </p>

          <MessengerIcons/>
        </div>

        <div class="images_block">
          <img
            class="logos"
            v-for="(url, index) in data.urls"
            :key="index"
            :src="url"/>
        </div>
      </div>
    </div>

    <div class="footer_section"></div>
  </section>
</template>

<script>
import { ref } from 'vue'
import MessengerIcons from '../ui/MessengerIcons.vue'

export default {
  name: 'PackageTours',
  components: {
    MessengerIcons
  },
  props: {
  },
  methods: {
    loadCommonData () {
      fetch('/data/package_tours/package_tours_data.json')
        .then((res) => res.json())
        .then(data => {
          this.data = data[0]
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  created () {
    this.loadCommonData()
  },
  setup () {
    const data = ref({})

    return {
      data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

p {
  color: #052831;
  font-size: 22px;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1921px) {
    font-size: 1.3vw;
  }
}

.package-tours {
  position: relative;
  display: flex;
  width: 100vw;
  background-color: #A9C8D6;
  background-image: url('../icons/package_tours/background.svg');
  background-size: cover;

  &:after {
    content: '';
    background: linear-gradient(rgba(169, 200, 214, 1), rgba(169, 200, 214, 0.47), rgba(169, 200, 214, 0));
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.package-tours__container {
  position: relative;
  padding-bottom: 220px;
  z-index: 2;
}

.container__info {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 500;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 10%;
  width: 40%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
    align-items: center;
    gap: 4vw;
  }
}

.images_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 60%;
  gap: 1vw;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    width: 100%;
  }
}

.logos {
  width: 27%;

  @media screen and (max-width: 1024px) {
    width: 30%;
  }
}

.footer_section {
  position: absolute;
  bottom: 0px;
  background-image: url('../icons/common/white_town.png');
  background-position: 40%;
  width: 100vw;
  height: 130px;
}
</style>
