import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fonts from './assets/scss/_fonts.scss'
import resetScss from './assets/scss/_reset.scss'
import baseScss from './assets/scss/_base.scss'

createApp(App)
  .use(store)
  .use(router)
  .use(fonts)
  .use(resetScss)
  .use(baseScss)
  .mount('#app')
