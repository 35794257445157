<template>
  <Header ref='header'></Header>
  <MainSlider></MainSlider>
  <ThematicTours></ThematicTours>
  <PackageTours></PackageTours>
  <BusinessTours></BusinessTours>
  <Reviews></Reviews>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/blocks/Header.vue'
import MainSlider from '@/components/blocks/MainSlider.vue'
import ThematicTours from '@/components/blocks/ThematicTours.vue'
import PackageTours from '@/components/blocks/PackageTours.vue'
import BusinessTours from '@/components/blocks/BusinessTours.vue'
import Reviews from '@/components/blocks/Reviews.vue'
import Footer from '@/components/blocks/Footer.vue'

import { useResizeObserver } from '@vueuse/core'
import { ref } from 'vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    MainSlider,
    ThematicTours,
    PackageTours,
    BusinessTours,
    Reviews,
    Footer
  },
  props: {
  },
  setup () {
    const header = ref(null)

    useResizeObserver(header, (entries) => {
      const entry = entries[0]
      const { height } = entry.contentRect
      const container = document.querySelectorAll('.container')
      container.forEach(el => {
        el.style.paddingTop = 'calc(' + height + 'px + 5vw + 20px)'
      })
    })

    return {
      header
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
