<template>
  <section class="thematic" id="thematic_tours_id">
    <div class="thematic__container container">
      <h1 class="container__title">
        {{ commonData.title }}
      </h1>

      <p class="thematic__top-info">
        {{ commonData.top_description }}
      </p>

      <swiper
        :slidesPerView="1"
        :spaceBetween="30"
        :pagination="{
          clickable: true,
        }"
        :autoplay="{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
        :modules="modules"
        class="thematic__swiper">

        <swiper-slide
          v-for="(slide, index) in slides"
          :key="index">
          <div class="slide">
            <div class="slide__img">
              <img :src="slide.url"/>
            </div>

            <div class="slide__info">
              <h1 class="slide__info-title">
                {{ slide.title }}
              </h1>

              <p class="slide__info-description">
                {{ slide.description }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="thematic__bottom-info">
        <p>
          {{ commonData.bottom_description }}
        </p>

        <MessengerIcons/>
      </div>
    </div>

    <div class="thematic__footer"></div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/scss'
import 'swiper/scss/pagination'
import { Pagination, Autoplay } from 'swiper/modules'
import { ref } from 'vue'
import MessengerIcons from '../ui/MessengerIcons.vue'

export default {
  name: 'ThematicTours',
  components: {
    Swiper,
    SwiperSlide,
    MessengerIcons
  },
  props: {
  },
  methods: {
    loadCommonData () {
      fetch('/data/thematic_tours/thematic_tours_common_data.json')
        .then((res) => res.json())
        .then(data => {
          this.commonData = data[0]
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    loadSliderData () {
      fetch('/data/thematic_tours/thematic_tours_slider_data.json')
        .then((res) => res.json())
        .then(data => {
          this.slides = data
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  created () {
    this.loadCommonData()
    this.loadSliderData()
  },
  setup () {
    const commonData = ref({})
    const slides = ref({})

    return {
      modules: [Pagination, Autoplay],
      slides,
      commonData
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  color: #052831;
}

.thematic {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #ECEADD;
  background-image: url('../icons/thematic_tours/background.svg');
  background-size: cover;

  @media screen and (max-width: 1024px) {
    height: auto;
    padding-bottom: 150px;
  }

  &__top-info {
    margin-bottom: 2vw;
    max-width: 60%;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      text-align: center;
      font-size: 14px;
    }

    @media screen and (min-width: 1921px) {
      font-size: 1.5vw;
    }
  }

  &__swiper {
    margin-bottom: 2vw;

    @media screen and (max-width: 1024px) {
      color: #ECEADD;
    }

    &:hover {
      cursor: grab;
    }
  }

  &__bottom-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 24px;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
        text-align: center;
    }

    @media screen and (min-width: 1921px) {
      font-size: 1.7vw;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0px;
    background-image: url('../icons/common/blue_town.png');
    background-position: center;
    width: 100vw;
    height: 132px;
  }
}

.container {
  padding-bottom: 220px;

    &__title {
      @media screen and (max-width: 1024px) {
        text-align: center;
      }
  }
}

.slide {
  display: flex;
  margin-right: 10px;
  background: #8DB4C5;
  border-radius: 3.4375vw;
  box-shadow: 6px 6px 8px #0528314D;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    box-shadow: none;
  }

  &__img {
    width: 40%;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }

  &__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3.4375vw 0 0 3.4375vw;

    @media screen and (max-width: 1024px) {
      width: 100%;
      border-radius: 3.4375vw 3.4375vw 0 0;
    }
  }

  &__info {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-height: auto;
      padding: 4vw 1vw 10vw 3vw;
    }

    &-title {
      margin-bottom: 1vw;
      font-weight: 700;
      font-size: 28px;
      color: #052831;

      @media screen and (max-width: 1024px) {
        text-align: center;
        font-size: 18px;
        color: #ECEADD;
      }

      @media screen and (min-width: 1921px) {
        font-size: 2.2vw;
      }
    }

    &-description {
      font-size: 22px;
      color: #052831;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
        color: #ECEADD;
      }

      @media screen and (min-width: 1921px) {
        font-size: 1vw;
      }
    }
  }
}

:deep(.swiper-pagination-bullet),
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  --swiper-theme-color: #E6B435;
}
</style>
