<template>
  <div
    class="messenger-icons"
    v-show="color">
    <a href="https://vk.com/vtstravel">
      <img
        src="../icons/common/messanger_icons/VK_icon_black.png"
        alt="ВКонтакте">
    </a>

    <a href="https://t.me/vashtoursovetnik">
      <img
        src="../icons/common/messanger_icons/TG_icon_black.svg"
        alt="Telegram">
    </a>

    <a href="https://wa.me/79261717162">
      <img
      src="../icons/common/messanger_icons/WA_icon_black.svg"
      alt="WhatsApp">
    </a>
  </div>

  <div
    class="messenger-icons"
    v-show="!color">
    <a href="https://vk.com/vtstravel">
      <img
        src="../icons/common/messanger_icons/VK_icon_white.png"
        alt="ВКонтакте">
    </a>

    <a href="https://t.me/vashtoursovetnik">
      <img
      src="../icons/common/messanger_icons/TG_icon_white.png"
      alt="Telegram">
    </a>

    <a href="https://wa.me/79261717162">
      <img
      src="../icons/common/messanger_icons/WA_icon_white.svg"
      alt="WhatsApp">
    </a>
  </div>
</template>

<script>
export default {
  name: 'MessengerIcons',
  components: {
  },
  props: {
    color: {
      type: Boolean,
      default: true
    }
  },
  setup () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.messenger-icons {
  display: flex;
  gap: 1.66vw;
  width: fit-content;
}
</style>
