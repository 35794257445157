<template>
  <section
    class="main"
    id="main_id">
    <swiper
      :effect="'fade'"
      :initialSlide = "0"
      :pagination="{
        clickable: true,
      }"
      :autoplay="{
        delay: 3500,
        disableOnInteraction: false,
      }"
      :modules="modules"
      class="mySwiper">
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index">
          <img
            class="slide_image"
            :src="slide.url"/>

            <div class="slogan" v-bind:style="{'top': slide.top, 'left': slide.left}">
              <p class="slogan__description" v-bind:style="{'max-width': slide.width}">
                {{ slide.slogan_description }}
              </p>
          </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { EffectFade, Autoplay, Pagination } from 'swiper/modules'
import { ref } from 'vue'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    loadData () {
      fetch('/data/main_slider/main_slider_data.json')
        .then((res) => res.json())
        .then(data => {
          this.slides = data
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  created () {
    this.loadData()
  },
  setup () {
    const slides = ref({})

    return {
      modules: [EffectFade, Autoplay, Pagination],
      slides
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
.main {
  display: flex;
  flex-direction: column;
}

.swiper {
  width: 100vw;
  height: 100vh;

  &:hover {
    cursor: grab;
  }
}

.swiper-slide {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }
}

.slide_image {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  z-index: 0
}

.slogan {
  position: absolute;
  transform: translateY(-50%);
  z-index: 2;

  @media screen and (max-width: 1024px) {
    top: 75% !important;
    left: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__description {
    font-family: 'Gabriela', serif;
    color: rgba(211, 241, 255, 0.9);
    text-shadow:
    1px 1px 2px rgb(169, 200, 214),
    0 0 1em #052831,
    0 0 0.2em #052831;
    text-align: center;

    @media screen and (max-width: 1024px) {
      max-width: 70vw !important;
      font-size: 3vh;
    }

    @media screen and (min-width: 1024px) {
      font-size: 3.5vh;
    }
  }
}

:deep(.swiper-pagination-bullet),
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  --swiper-theme-color: #E6B435;
}
</style>
