<template>
  <section class="reviews" id="reviews_id">
    <div class="container">
      <h2 class="container__title">
        Отзывы
      </h2>

      <p class="container__info">
        {{ data.paragraph_1 }}
      </p>

      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :initialSlide = "2"
        :coverflowEffect="{
          rotate: 0,
          stretch: 10,
          depth: 2000,
          modifier: 1,
          slideShadows: false
        }"
        :autoplay="{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper">
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="index">
          <img
            class="slide_three"
            :src="slide.url"/>
        </swiper-slide>
      </swiper>
    </div>

    <div class="footer_section"></div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css'
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules'
import { ref } from 'vue'

export default {
  name: 'RevuewsVue',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
  },
  methods: {
    loadCommonData () {
      fetch('/data/reviews/reviews_common_data.json')
        .then((res) => res.json())
        .then(data => {
          this.data = data[0]
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    loadSliderData () {
      fetch('/data/reviews/reviews_slider_data.json')
        .then((res) => res.json())
        .then(data => {
          this.slides = data
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  created () {
    this.loadCommonData()
    this.loadSliderData()
  },
  setup () {
    const slides = ref({})
    const data = ref({})

    return {
      modules: [EffectCoverflow, Autoplay, Pagination],
      slides,
      data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.reviews {
  position: relative;
  display: flex;
  width: 100vw;
  background-color: #A9C8D6;
  background-image: url('../icons/reviews/background.svg');
  background-size: cover;

  &:after {
    content: '';
    background: linear-gradient(rgba(169, 200, 214, 1), rgba(169, 200, 214, 0.47), rgba(169, 200, 214, 0));
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.container {
  position: relative;
  padding: 220px 0px;
  z-index: 2;

  &__title {
    text-align: center;
  }

  &__info {
    max-width: 500px;
    margin: auto auto;
    margin-bottom: 3.2vw;
    text-align: center;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 22px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    @media screen and (min-width: 1921px) {
      font-size: 1.3vw;
    }
  }
}

.swiper {
  &:hover {
    cursor: grab;
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide_three {
  width: 60%;
}

:deep(.swiper-pagination-bullet),
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  --swiper-theme-color: #E6B435;
}

.footer_section {
  position: absolute;
  bottom: 0px;
  background-image: url('../icons/common/black_town.png');
  background-position: 20%;
  width: 100vw;
  height: 132px;
  z-index: 2;
}
</style>
