<template>
    <div class="burger">
      <button class="burger__btn" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
          <span class="top"></span>
          <span class="mid"></span>
          <span class="bottom"></span>
        </button>
      <transition name="translateX">
        <nav v-show="navOpen">
          <div class="burger__wrapper">
            <ul class="burger__list">
              <li class="burger__item" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
                <a href="#thematic_tours_id">Тематические туры</a>
              </li>

              <li class="burger__item" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
                <a href="#package_tours_id">Пакетные туры</a>
              </li>

              <li class="burger__item" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
                <a href="#business_tours_id">Деловой туризм</a>
              </li>

              <li class="burger__item" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
                <a href="#reviews_id">Отзывы</a>
              </li>

              <li class="burger__item" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
                <a href="#footer_id">Контакты</a>
              </li>
            </ul>

            <MessengerIcons :color="false"/>
          </div>
        </nav>
      </transition>
    </div>
</template>

<script>
import { ref } from 'vue'
import MessengerIcons from '../ui/MessengerIcons.vue'

export default {
  name: 'BurgerMenu',
  components: {
    MessengerIcons
  },
  props: {
  },
  setup () {
    const navOpen = ref(false)

    return {
      navOpen
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.burger {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  nav {
    width: 40%;
    height: 100%;
    background: #373a47;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    overflow-y: scroll;

    @media screen and (max-width: 854px) {
      margin: auto;
      right: 0;
      bottom: 0px;
      width: 90%;
      height: 97%;
      opacity: 95%;
    }
  }

  &__btn {
    display: block;
    width: 35px;
    height: 35px;
    border: none;
    position: relative;
    z-index: 100;
    appearance: none;
    cursor: pointer;
    outline: none;
    background: rgba(169, 200, 214, 0);

    span {
      display: block;
      width: 29px;
      height: 4.85px;
      margin: auto;
      background: #052831;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all .4s ease;

      &.top {
        transform: translateY(-8px);
      }

      &.bottom {
        transform: translateY(8px);
      }
    }
    &.active {
      .top {
        transform: rotate(-45deg);
      }
      .mid {
        transform: translateX(-20px) rotate(360deg);
        opacity: 0;
        background-color: #373a47;
      }
      .bottom {
        transform: rotate(45deg);
      }
    }
  }

  &__wrapper {
    padding-top: 50px;
    height: 100vh;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 75%;
    margin: 0;
    padding-top: 50px;
    text-align: center;
    list-style: none;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
  }

  &__item {
    a {
      text-decoration: none;
      line-height: 1.6em;
      font-size: 1.6em;
      padding: .5em;
      display: block;
      color: white;
      transition: .4s ease;

      &:hover {
        background: lightgrey;
        color: dimgrey;
      }
    }
  }
}

:deep(.messenger-icons) {
  margin: auto;
}

.translateX-enter{
  transform:translateX(-200px);
  opacity: 0;
}

.translateX-enter-active {
  transform-origin: top left 0;
  transition:.2s ease;
}

.translateX-leave-active{
  transform-origin: top left 0;
  transition: .2s ease;
  transition-delay: .8s;
}

.translateX-leave-to{
  transform: translateX(-200px);
  opacity: 0;
}
</style>
