<template>
      <nav class="navigation">
        <ul class="list">
          <li class="list__item">
            <a class="list__link" href="#thematic_tours_id">
              Тематические туры
            </a>
          </li>

          <li class="list__item">
            <a class="list__link" href="#package_tours_id">
              Пакетные туры
            </a>
          </li>

          <li class="list__item">
            <a class="list__link" href="#business_tours_id">
              Деловой туризм
            </a>
          </li>

          <li class="list__item">
            <a class="list__link" href="#reviews_id">
              Отзывы
            </a>
          </li>

          <li class="list__item">
            <a class="list__link" href="#footer_id">
              Контакты
            </a>
          </li>
        </ul>
      </nav>
</template>

<script>
export default {
  name: 'NavigationVue',
  components: {

  },
  props: {
  },
  setup () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .navigation {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2vw;

      &__item {
        white-space: nowrap;
      }

      &__link {
        font-family: 'Maven Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #052831;
        text-decoration: none;

        @media screen and (min-width: 1921px) {
          font-size: 1.2vw;
        }
      }
    }
  }
</style>
