<template>
  <section class="tours" id="business_tours_id">
    <div class="container">
      <div class="container__info">
        <div class="description">
          <h2 class="description__title container__title">
            Деловой туризм
          </h2>

          <p class="description__paragraph-1">
            {{ data.paragraph_1 }}
          </p>

          <img
          class="description__icon-1"
          :src="data.icon_1"/>

          <p class="description__text-icon-1">
            {{ data.text_icon_1 }}
          </p>

          <img
          class="description__icon-2"
          :src="data.icon_2"/>

          <p class="description__text-icon-2">
            {{ data.text_icon_2 }}
          </p>

          <img
          class="description__icon-3"
          :src="data.icon_3"/>

          <p class="description__text-icon-3">
            {{ data.text_icon_3 }}
          </p>

          <img
          class="description__icon-4"
          :src="data.icon_4"/>

          <p class="description__text-icon-4">
            {{ data.text_icon_4 }}
          </p>

          <p class="description__paragraph-2">
            {{ data.paragraph_2 }}
          </p>

          <div class="description__bottom-block">
            <p class="description__bottom-text">
              {{ data.bottom_text }}
            </p>

            <MessengerIcons/>
          </div>

          <div
            class="description__mainImage"
            :style="{backgroundImage: 'url(' + data.mainImageUrl + ')' }">
          </div>
        </div>
      </div>
    </div>

    <div class="footer_section"></div>
  </section>
</template>

<script>
import { ref } from 'vue'
import MessengerIcons from '../ui/MessengerIcons.vue'

export default {
  name: 'BusinessTours',
  components: {
    MessengerIcons
  },
  props: {
  },
  methods: {
    loadData () {
      fetch('/data/business_tours/business_tours_data.json')
        .then((res) => res.json())
        .then(data => {
          this.data = data[0]
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  created () {
    this.loadData()
  },
  setup () {
    const data = ref({})

    return {
      data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

p {
  font-family: 'Maven Pro', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #052831;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1921px) {
    font-size: 1.7vw;
  }
}

.tours {
  position: relative;
  display: flex;
  width: 100vw;
  background-color: #ECEADD;
  background-image: url('../icons/business_tours/background.svg');
  background-size: cover;

  &:after {
    content: '';
    background: linear-gradient(rgba(236, 234, 221, 1), rgba(236, 234, 221, 0), rgba(236, 234, 221, 0));
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 1;
  }
}

.container {
  padding-bottom: 250px;
}

  .description {
    display: grid;
    grid-template-columns: 4% 26% 4% 26% 35%;
    grid-column-gap: 1%;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 33% 33% 33%;
      grid-row-gap: 2vw;
      grid-column-gap: 0%;
    }

    &__mainImage {
      grid-column: 5;
      grid-row: 1/7;
      background-size: cover;

      @media screen and (max-width: 1024px) {
        grid-column: 1/4;
        grid-row: 3;
        height: 30vw;
      }
    }

    &__title {
      grid-column: 1/4;
      grid-row: 1;

      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }

    &__paragraph-1 {
      grid-column: 1/5;
      grid-row: 2;
      margin-bottom: 3vw;

      @media screen and (max-width: 1024px) {
        grid-column: 1/4;
        grid-row: 2;
        text-align: center;
      }
    }

    &__icon-1 {
      grid-column: 1;
      grid-row: 3;

      @media screen and (max-width: 1024px) {
        grid-column: 1;
        grid-row: 4;
      }
    }

    &__text-icon-1 {
      grid-column: 2;
      grid-row: 3;
      margin: 0 0 2vw 1vw;

      @media screen and (max-width: 1024px) {
        grid-column: 2/4;
        grid-row: 4;
      }
    }

    &__icon-2 {
      grid-column: 3;
      grid-row: 3;

      @media screen and (max-width: 1024px) {
        grid-column: 1;
        grid-row: 5;
      }
    }

    &__text-icon-2 {
      grid-column: 4;
      grid-row: 3;
      margin: 0 0 2vw 1vw;

      @media screen and (max-width: 1024px) {
        grid-column: 2/4;
        grid-row: 5;
      }
    }

    &__icon-3 {
      grid-column: 1;
      grid-row: 4;

      @media screen and (max-width: 1024px) {
        grid-column: 1;
        grid-row: 6;
      }
    }

    &__text-icon-3 {
      grid-column: 2;
      grid-row: 4;
      margin: 0 0 2vw 1vw;

      @media screen and (max-width: 1024px) {
        grid-column: 2/4;
        grid-row: 6;
      }
    }

    &__icon-4 {
      grid-column: 3;
      grid-row: 4;

      @media screen and (max-width: 1024px) {
        grid-column: 1;
        grid-row: 7;
      }
    }

    &__text-icon-4 {
      grid-column: 4;
      grid-row: 4;
      margin: 0 0 2vw 1vw;

      @media screen and (max-width: 1024px) {
        grid-column: 2/4;
        grid-row: 7;
      }
    }

    &__paragraph-2 {
      grid-column: 1/5;
      grid-row: 5;
      margin-top: 3vw;
      margin-bottom: 3vw;

      @media screen and (max-width: 1024px) {
        grid-column: 1/4;
        grid-row: 8;
        text-align: center;
      }
    }

    &__bottom-block {
      grid-column: 1/5;
      grid-row: 6;

      @media screen and (max-width: 1024px) {
        grid-column: 1/4;
        grid-row: 9;
        align-items: center;
      }
    }

    &__bottom-text {
      margin-bottom: 3vw;

      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }
  }

  :deep(.messenger-icons) {
    @media screen and (max-width: 1024px) {
      margin: auto;
    }
  }

.footer_section {
  position: absolute;
  bottom: 0px;
  background-image: url('../icons/common/blue_town.png');
  background-position: 35%;
  width: 100vw;
  height: 132px;
}
</style>
